<template>
    <Layout>
        <div id="page" class="card">
            <div class="card-header">
                <div class="card-title">
                    Resposta de Formulário de Aplicação
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 mb-2">
                        <p><b>Nome: </b>{{ name }}</p>
                        <p><b>E-mail: </b>{{ email }}</p>
                        <p v-if="phone"><b>WhatsApp: </b>{{ phone }}</p>
                    </div>
                    <div class="col-12 mt-2" v-for="(question, index) in form.questions"
                         :key="'question-' + question.id">
                        <hr v-if="index > 0" class="bg-primary">
                        <b>{{ index + 1 }}. </b><b v-html="question.question" class="mb-2"></b>
                        <div v-if="question.type === 1" class="mb-2 italic">
                            {{ answers[question.id] }}
                        </div>
                        <div v-else>
                            <p v-for="(alternative, i) in question.alternatives" :key="'alternative-' + alternative.id">
                                <i class="fa fa-check-circle text-success" v-if="answers[question.id].indexOf(alternative.id) > -1"></i>
                                <span v-else>{{ alphabet[i] }}) </span>
                                <span class="italic"
                                      :class="answers[question.id].indexOf(alternative.id) > -1 ? 'fw-bold' : ''">
                                    {{ alternative.alternative }}
                                </span>
                            </p>
                        </div>
                    </div>

                    <div class="col-12 mt-3">
                        <hr class="bg-primary">
                        <label class="form-label">Situação <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-12 mb-4 d-flex">
                        <b-form-radio id="invalidCheck01"
                                      v-model="status"
                                      value="approved"
                                      name="agree">
                            Aprovado
                        </b-form-radio>
                        <b-form-radio id="invalidCheck02"
                                      value="failed"
                                      v-model="status"
                                      name="agree"
                                      class="ml-3">
                            Reprovado
                        </b-form-radio>
                    </div>

                    <div class="col-12 mb-3">
                        <label for="feedback" class="form-label">Feedback <span class="text-danger">*</span></label>
                        <textarea id="feedback" v-model="feedback" rows="10" class="form-control"></textarea>
                    </div>
                    <div class="col-12 text-end mb-3">
                        <button type="button" class="btn btn-outline-primary" id="save" @click="save">Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>

import Layout from "@/components/layout/main.vue";
import http from '@/http'
import {endLoading, startLoading} from "@/components/composables/spinners";
import {ValidateForm} from "@/components/composables/cruds";

export default {
    components: {Layout},
    data() {
        return {
            form: [],
            answers: [],
            name: '',
            email: '',
            phone: '',
            feedback: '',
            status: '',
            alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        }
    },

    methods: {
        getData() {
            this.$store.commit('api/setSpinner', true);
            startLoading('page');

            const id = this.$route.params.id;

            http.get(`form-applications/${id}?with[]=form`)
                .then(response => {
                    this.form = response.data.form;
                    this.answers = response.data.answers;
                    this.name = response.data.name;
                    this.email = response.data.email;
                    this.phone = response.data.phone;
                })
                .catch(e => {
                    console.error('Data Form: ', e);
                    this.$notifyError(e.response.data);
                })
                .finally(() => {
                    this.$store.commit('api/setSpinner', false);
                    endLoading('page')
                })
        },

        save() {
            this.$store.commit('api/setSpinner', true);
            startLoading('page', 'save');

            const id = this.$route.params.id
            const formData = {
                feedback: this.feedback,
                status: this.status,
                id,
                _method: 'PUT'
            }

            const required = {
                feedback: 'Feedback',
                approved: 'Situação',
            };
            if (!ValidateForm(this.$root, formData, required)) {
                endLoading('page', 'save');
                this.$store.commit('api/setSpinner', false);
                return;
            }

            http.post(`form-applications/${id}`, formData)
                .then(() => {
                    this.$notifySuccess('Feedback salvo com sucesso!');
                    // setTimeout(() => {
                    //     this.$router.go(-1);
                    // }, 500)
                })
                .catch(e => {
                    console.error('Response Form Applications: ', e)
                    this.$notifyError(e.response.data);
                })
                .finally(() => {
                    this.$store.commit('api/setSpinner', false);
                    endLoading('page', 'save')
                })
        }
    },

    mounted() {
        this.getData();
    }
}

</script>
